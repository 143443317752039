function videoHandler(event, videoContainer, video, button) {

    if (event.type === 'playing') {
        video.setAttribute("controls", "controls");
        videoContainer.setAttribute('data-video-playing', '');
        if (button.dataset.buttonActive === 'true') button.dataset.buttonActive = 'false';
    } else if (event.type === 'pause') {
        videoContainer.removeAttribute('data-video-playing');
        video.removeAttribute("controls");
        if (button.dataset.buttonActive === 'false') button.dataset.buttonActive = 'true';
    }
}

function toggleVideo(videoContainer, video) {
    if (video.paused) {
        videoContainer.setAttribute('data-video-playing', '');
        video.play();
    } else {
        videoContainer.removeAttribute('data-video-playing');
        video.pause();
    }
}

export function INIT_VIDEOS() {
    const VIDEO_CONTAINERS = document.querySelectorAll('.jobs__video');

    VIDEO_CONTAINERS.forEach(VIDEO_CONTAINER => {
        const VIDEO_ELEMENT = VIDEO_CONTAINER.querySelector('video'),
            VIDEO_BUTTON = VIDEO_CONTAINER.querySelector('button.is--play');

        if (!VIDEO_ELEMENT || !VIDEO_BUTTON) return;

        VIDEO_ELEMENT.addEventListener('playing', e => videoHandler(e, VIDEO_CONTAINER, VIDEO_ELEMENT, VIDEO_BUTTON), false);

        VIDEO_ELEMENT.addEventListener('pause', e => videoHandler(e, VIDEO_CONTAINER, VIDEO_ELEMENT, VIDEO_BUTTON), false);

        VIDEO_ELEMENT.addEventListener('click', e => {
            e.preventDefault();
            toggleVideo(VIDEO_CONTAINER, VIDEO_ELEMENT);
        });

        VIDEO_BUTTON.addEventListener('click', e => {
            e.preventDefault();
            VIDEO_ELEMENT.play();
        })
    })
}
